import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmFile, WmFileList } from "@watermarkinsights/ripple-react";
import { API_APPLICATION_ROOT } from "../../../env-config";
import { fetchWrapper } from "../../../features/common/fetchWrapper";
import { format } from "date-fns";
import { isNullOrEmpty } from "../../../static/js/commons";
import { generateUTCDate } from "../../../features/common/commonActions";
export default function ResourceAttachment({ id, displayLabel, attachedFilesList, appTemplateView, files, isExternal, }) {
    let filesList = attachedFilesList === null || attachedFilesList === void 0 ? void 0 : attachedFilesList.map((file) => (Object.assign(Object.assign({}, file), { fileActions: "download" })));
    const downloadFile = (fileUuid, listOfFiles) => {
        let uploadedFile = listOfFiles.filter((file) => file.uuid === fileUuid)[0];
        let getUrlForDownload = `${API_APPLICATION_ROOT}api${isExternal ? "/public" : ""}/files/${fileUuid}/sign-url-download`;
        fetchWrapper("POST", getUrlForDownload, {}, {
            file_uuid: fileUuid,
            file_path: uploadedFile.file_path,
        }).then((data) => {
            let [downloadUrlPromise, error] = data;
            if (downloadUrlPromise) {
                downloadUrlPromise.then((response) => {
                    window.open(response.url);
                });
            }
            else {
                throw Error(error);
            }
        });
    };
    return (_jsxs("div", Object.assign({ className: `attachment-component ${!appTemplateView ? "attchmnt-student-view" : ""}`, id: "attachment-comp-" + id }, { children: [displayLabel ? (_jsx("label", Object.assign({ className: "attachmnt-label" }, { children: displayLabel }))) : null, _jsx(WmFileList, Object.assign({ "show-info": "size", placeholder: undefined }, { children: filesList === null || filesList === void 0 ? void 0 : filesList.map((file) => {
                    return (_jsx(WmFile, { id: file.id, name: file.name, type: file.type, lastUpdated: !isNullOrEmpty(file === null || file === void 0 ? void 0 : file.lastUpdated)
                            ? format(generateUTCDate(new Date(file.lastUpdated)), "MM/dd/yyyy h:mm a")
                            : "", fileActions: file.fileActions, onWmFileDownload: (event) => {
                            downloadFile(event.target.id, files);
                        }, placeholder: undefined }, file.id));
                }) }))] })));
}
