import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { getLoggedInUserInfo, getLoggedInUserInfoProgramApp, userSelector, } from "../../features/login/UserSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProgramApplicationLandingPage from "./ProgramApplicationLandingPage";
import StudentLandingPage from "./StudentLandingPage";
import { initiateGainsight } from "../../static/js/gainsight_integration";
const MainLandingPage = (props) => {
    const { userInfo, userInfoProgApp } = useSelector(userSelector);
    const dispatch = useDispatch();
    let isAdminOrNodeAdmin = false;
    let isStudent = false;
    if (userInfoProgApp && userInfoProgApp.applicationsUser) {
        let role = userInfoProgApp.applicationsUser.sllRole;
        if (role &&
            (role === "admin" ||
                role === "faculty" ||
                (role == "faculty" && userInfoProgApp.sllUser.org_uuids.length > 0))) {
            isAdminOrNodeAdmin = true;
        }
        else {
            isStudent = true;
        }
    }
    useEffect(() => {
        if (userInfo) {
            if (userInfo.gainsightKey !== undefined) {
                var appUser = userInfo.gatewaysUser || userInfo.applicationsUser;
                var gainsightsData = {
                    user: {
                        email: appUser.email,
                        firstName: appUser.firstName,
                        lastName: appUser.lastName,
                        role: appUser.role,
                        id: appUser.personId || appUser.uuid,
                    },
                    account: {
                        name: userInfo.institutionName,
                        role: appUser.role,
                        id: appUser.institutionUuid,
                    },
                    gainsightKey: userInfo.gainsightKey
                };
                initiateGainsight(gainsightsData);
            }
        }
    }, [userInfo]);
    useEffect(() => {
        dispatch(getLoggedInUserInfoProgramApp());
        localStorage.setItem("goToHome", "/programApplications");
    }, []);
    useEffect(() => {
        var _a, _b;
        if ((_b = (_a = userInfoProgApp === null || userInfoProgApp === void 0 ? void 0 : userInfoProgApp.sllUser) === null || _a === void 0 ? void 0 : _a.available_apps) === null || _b === void 0 ? void 0 : _b.includes("gateways")) {
            dispatch(getLoggedInUserInfo());
        }
    }, [userInfoProgApp]);
    return (_jsx(_Fragment, { children: isAdminOrNodeAdmin ? (_jsx(ProgramApplicationLandingPage, {})) : isStudent ? (_jsx(StudentLandingPage, {})) : (_jsx(_Fragment, {})) }));
};
export default MainLandingPage;
