import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Flyout from "../common/component/Flyout";
import translate from "../../i18n/translate";
import { useEffect, useState, useRef } from "react";
import { getI18nValueByKey, getClassByStatus, getStudentIdentifierForBackend, generateUTCDate, } from "../common/commonActions";
import { StudentApplicationStatus } from "./data/StudentApplicationStatus";
import { WmButton, WmFile, WmFileList, WmTabPanel, WmToggletip, WmUploader, } from "@watermarkinsights/ripple-react";
import { useSelector, useDispatch } from "react-redux";
import { openFlyout, closeFlyout, focusButtonComponent, focusNextListItem, isNullOrEmpty, getMessageForActivityTime, } from "../../static/js/commons";
import { applicationSelector, updateStudentSubmissionStatus, getSubmissionStatusCount, getStudentSubmissionRecord, removeFileFromApplicationSubmissionList, getAllApplications, } from "./ApplicationSlice";
import { useIntl } from "react-intl";
import ChangeSubmissionStatus from "./ChangeSubmissionStatus";
import { format } from "date-fns";
import useFileupload from "../../features/common/hooks/useFileUpload";
import Card from "../../features/common/component/Card";
import SubTabWmComponent from "../common/component/SubTabsWmComponent";
import AdminInternalNotes from "./AdminInternalNotes";
import WmActionModal from "../../features/common/component/WmActionModal";
import TextAreaField from "../../features/common/component/TextAreaField";
import ToggleDisplay from "../common/component/ToggleDisplay";
import ViewApplicationResponses from "./ViewApplicationResponses";
import { HOST_NAME } from "../../env-config";
const ENTER_KEY_CODE = 13;
const DOWN_ARROW_KEY_CODE = 40;
const UP_ARROW_KEY_CODE = 38;
const ESCAPE_KEY_CODE = 27;
export default function OpenStudentApplicationReview({ setStartStudentReview, studentApplication, showNavigationBar, applicationObject, setShowOpenRequirement, sourcePage, awaitingReviewSubmissionList, currentSubmissionIndex, setCurrentSubmissionIndex, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const dispatch = useDispatch();
    const { studentApplicationSubmission, isFetchingSubmissionCount, isFetchingData, isFetchingApplications, isFetchingStudentSubmissionRecord, } = useSelector(applicationSelector);
    const uploaderRef = useRef();
    const [attachedFiles, deletedFiles, uploadInProgress, deleteInProgress, onWmUploaderFilesSelected, onWmUploaderDownloadFile, onWmUploaderDeleteFile, setAttachedFiles, setHookMetadata, currentFiles,] = useFileupload({
        app: "APPLICATIONS",
        type: "APPLICATION_TEMPLATE_REVIEW",
        initialAttachments: studentApplicationSubmission.files,
        metadata: studentApplicationSubmission.studentApplicationUuid,
        componentRef: uploaderRef,
    });
    const [openMenu, setOpenMenu] = useState(false);
    let [updatedByEvent, setUpdatedByEvent] = useState(0);
    const [openChangeStatus, setOpenChangeStatus] = useState(false);
    const [noteForStudent, setNoteForStudent] = useState("");
    const [noteError, setNoteError] = useState(false);
    const [openStatusChangeModal, setOpenStatusChangeModal] = useState({ isOpen: false, status: "" });
    const [allowResubmission, setAllowResubmission] = useState(false);
    const [selectedTab, setSelectedTab] = useState("item-2");
    const [uploaderComponentFiles, setUploaderComponentFiles] = useState([]);
    const referenceFilesToggleTip = useIntl().formatMessage({
        id: "app.programApplications.student.review.submission.internal.reference.files.toggle.tip",
    });
    const moreInfoLabel = useIntl().formatMessage({
        id: "app.programApplications.student.review.submission.internal.reference.files.more.info",
    });
    const uploaderButtonText = useIntl().formatMessage({
        id: "app.requirement.uploadNewDocument",
    });
    const attachEmptyStateText = useIntl().formatMessage({
        id: "app.requirement.emptyStateText",
    });
    const internalRefMaterialsLabel = useIntl().formatMessage({
        id: "app.programApplications.student.review.submission.internal.reference.materials.header.label",
    });
    const notesTabLabel = useIntl().formatMessage({
        id: "app.programApplications.student.review.submission.internal.reference.tab.notes.label",
    });
    const filesTabLabel = useIntl().formatMessage({
        id: "app.programApplications.student.review.submission.internal.reference.tab.files.label",
    });
    const deniedStatusModalHeading = useIntl().formatMessage({
        id: "app.programApplications.student.review.denied.modal.header.text",
    }, {
        name: ((_a = (showNavigationBar
            ? awaitingReviewSubmissionList[currentSubmissionIndex]
            : studentApplication)) === null || _a === void 0 ? void 0 : _a.firstName) +
            " " +
            ((_b = (showNavigationBar
                ? awaitingReviewSubmissionList[currentSubmissionIndex]
                : studentApplication)) === null || _b === void 0 ? void 0 : _b.lastName),
    });
    const statusChangeModalSecondaryText = useIntl().formatMessage({
        id: "app.programApplications.student.review.status.change.modal.secondary.button",
    });
    const deniedModalPrimaryText = useIntl().formatMessage({
        id: "app.programApplications.student.review.submission.deny.button",
    });
    const condStatusModalHeading = useIntl().formatMessage({
        id: "app.programApplications.student.review.cond.accepted.modal.header.text",
    }, {
        name: ((_c = (showNavigationBar
            ? awaitingReviewSubmissionList[currentSubmissionIndex]
            : studentApplication)) === null || _c === void 0 ? void 0 : _c.firstName) +
            " " +
            ((_d = (showNavigationBar
                ? awaitingReviewSubmissionList[currentSubmissionIndex]
                : studentApplication)) === null || _d === void 0 ? void 0 : _d.lastName),
    });
    const condModalPrimaryText = useIntl().formatMessage({
        id: "app.programApplications.student.review.submission.conditionally.accept.button",
    });
    const acceptedStatusModalHeading = useIntl().formatMessage({
        id: "app.programApplications.student.review.accepted.modal.header.text",
    }, {
        name: ((_e = (showNavigationBar
            ? awaitingReviewSubmissionList[currentSubmissionIndex]
            : studentApplication)) === null || _e === void 0 ? void 0 : _e.firstName) +
            " " +
            ((_f = (showNavigationBar
                ? awaitingReviewSubmissionList[currentSubmissionIndex]
                : studentApplication)) === null || _f === void 0 ? void 0 : _f.lastName),
    });
    const acceptedStatusModalPrimaryText = useIntl().formatMessage({
        id: "app.programApplications.student.review.submission.accept.button",
    });
    const statusChangeModalInputLabel = useIntl().formatMessage({
        id: "app.programApplications.student.review.status.change.modal.text.area.label",
    }, {
        petitioner: applicationObject.type == "INTERNAL" ? "Student" : "Applicant",
    });
    const noteErrorText = useIntl().formatMessage({
        id: "app.programApplications.student.review.note.to.student.error.text",
    });
    const condAcceptedNote = useIntl().formatMessage({
        id: "app.programApplications.student.review.cond.accepted.modal.note",
    }, {
        petitioner: applicationObject.type == "INTERNAL" ? "students" : "applicant",
    });
    const viewAppHistoryLabel = useIntl().formatMessage({
        id: "app.programApplications.review.application.status.change.history.view.history.label",
    });
    const showNotetoStudentLabel = useIntl().formatMessage({
        id: "app.programApplications.review.application.status.change.history.show.note.to.student.label",
    }, {
        petitioner: applicationObject.type == "INTERNAL" ? "student" : "applicant",
    });
    const hideNotetoStudentLabel = useIntl().formatMessage({
        id: "app.programApplications.review.application.status.change.history.hide.note.to.student.label",
    }, {
        petitioner: applicationObject.type == "INTERNAL" ? "student" : "applicant",
    });
    const lessThanMintAgo = useIntl().formatMessage({
        id: "app.openStudentReview.lessThanMintAgo",
    });
    const mintAgo = useIntl().formatMessage({
        id: "app.openStudentReview.mintAgo",
    });
    const mintsAgo = useIntl().formatMessage({
        id: "app.openStudentReview.mintsAgo",
    });
    const hourAgo = useIntl().formatMessage({
        id: "app.openStudentReview.hourAgo",
    });
    const hoursAgo = useIntl().formatMessage({
        id: "app.openStudentReview.hoursAgo",
    });
    const isPageLoading = isFetchingData ||
        isFetchingStudentSubmissionRecord ||
        isFetchingSubmissionCount ||
        (sourcePage && isFetchingApplications);
    const tabOption = [
        {
            name: notesTabLabel,
            id: "tab1",
        },
        {
            name: filesTabLabel +
                ((uploaderComponentFiles === null || uploaderComponentFiles === void 0 ? void 0 : uploaderComponentFiles.length) > 0
                    ? " (" + (uploaderComponentFiles === null || uploaderComponentFiles === void 0 ? void 0 : uploaderComponentFiles.length) + ")"
                    : ""),
            id: "tab2",
        },
    ];
    const closeStudentReviewHandler = (event) => {
        event.preventDefault();
        setCurrentSubmissionIndex(0);
        dispatch(getSubmissionStatusCount({ applicationUuid: applicationObject.uuid }));
        closeFlyout();
        if (sourcePage === "landing-page") {
            dispatch(getAllApplications());
        }
        setStartStudentReview(false);
        if (setShowOpenRequirement !== undefined) {
            setShowOpenRequirement(false);
        }
    };
    const handleStudentNavigationWrapper = (event, diff) => {
        handleStudentNavigation(event, diff);
        setSelectedTab("item-2");
    };
    const handleStudentNavigation = (event, diff) => {
        event.preventDefault();
        const oldIndex = currentSubmissionIndex;
        const currentIndex = currentSubmissionIndex + diff;
        setUpdatedByEvent((prevCount) => prevCount + 1);
        if (currentIndex >= 0 &&
            awaitingReviewSubmissionList.length > currentIndex) {
            setCurrentSubmissionIndex(currentIndex);
            if (!isNullOrEmpty(applicationObject) &&
                getStudentIdentifierForBackend(awaitingReviewSubmissionList[currentIndex]) !==
                    getStudentIdentifierForBackend(awaitingReviewSubmissionList[oldIndex])) {
                dispatch(getStudentSubmissionRecord({
                    studentUuid: getStudentIdentifierForBackend(awaitingReviewSubmissionList[currentIndex]),
                    applicationUuid: applicationObject.uuid,
                }));
            }
        }
    };
    const handleKeyDown = (status) => (e) => {
        var _a;
        switch (e.keyCode) {
            case ENTER_KEY_CODE:
                setAllowResubmission(false);
                setOpenStatusChangeModal({
                    isOpen: true,
                    status,
                });
                return;
            case DOWN_ARROW_KEY_CODE:
                e.preventDefault();
                focusNextListItem(DOWN_ARROW_KEY_CODE);
                return;
            case UP_ARROW_KEY_CODE:
                e.preventDefault();
                focusNextListItem(UP_ARROW_KEY_CODE);
                return;
            case ESCAPE_KEY_CODE:
                setOpenMenu(false);
                (_a = document.getElementById("accept-dropdown-btn")) === null || _a === void 0 ? void 0 : _a.focus();
                return;
            default:
                return;
        }
    };
    const reviewStatusHandler = (status) => {
        let studentApplicationId = (showNavigationBar
            ? awaitingReviewSubmissionList[currentSubmissionIndex]
            : studentApplication).studentApplicationUuid;
        let link = `${HOST_NAME}/externalApplications/${applicationObject.uuid}/submission-id/${studentApplicationId}`;
        dispatch(updateStudentSubmissionStatus({
            studentUuid: getStudentIdentifierForBackend(studentApplicationSubmission),
            applicationUuid: applicationObject.uuid,
            applicationStatus: status,
            allowResubmission,
            noteForStudent,
            resubmissionLink: link,
        }));
        setTimeout(() => {
            focusButtonComponent("status-change-btn");
        }, 100);
    };
    const navigationFooter = () => {
        return (_jsxs("footer", Object.assign({ className: "footer navigate-students static-footer flex-row -justified -verticallycentered" }, { children: [_jsx("div", Object.assign({ className: "footer-buttons" }, { children: currentSubmissionIndex !== 0 && (_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "previous-student-btn", onClick: (event) => handleStudentNavigationWrapper(event, -1), disabled: uploadInProgress || deleteInProgress, placeholder: undefined }, { children: translate("app.openStudentReview.previousStudentButton") }))) })), _jsx("div", Object.assign({ className: "student-count-info" }, { children: translate("app.openStudentReview.reviewingText", {
                        count: currentSubmissionIndex + 1,
                        total: awaitingReviewSubmissionList.length,
                    }) })), _jsx("div", Object.assign({ className: "footer-buttons" }, { children: awaitingReviewSubmissionList.length > 1 &&
                        currentSubmissionIndex <
                            awaitingReviewSubmissionList.length - 1 && (_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "next-student-btn", onClick: (event) => handleStudentNavigationWrapper(event, 1), disabled: uploadInProgress || deleteInProgress, placeholder: undefined }, { children: translate("app.openStudentReview.nextStudentButton") }))) }))] })));
    };
    const renderStatusChangeModal = (status, isOpen) => {
        let modalHeading = "";
        let modalContent = _jsx(_Fragment, {});
        let primaryText = "";
        let primaryClick = (event) => { };
        let secondaryText = statusChangeModalSecondaryText;
        switch (status) {
            case "DENIED":
                modalHeading = deniedStatusModalHeading;
                primaryText = deniedModalPrimaryText;
                modalContent = (_jsxs("div", Object.assign({ className: "modal-body status-change-modal-content" }, { children: [_jsx("p", Object.assign({ className: "info-text" }, { children: translate("app.programApplications.student.review.denied.modal.content", {
                                petitioner: applicationObject.type == "INTERNAL"
                                    ? "student's"
                                    : "applicant",
                            }) })), _jsx(TextAreaField, { fieldLabel: statusChangeModalInputLabel, fieldId: "status-change-note", isRequired: false, textareaError: false, fieldValue: noteForStudent, onChangeHandler: (event) => {
                                setNoteForStudent(event.target.value);
                            }, placeHolder: "", maxLength: 1000 }), applicationObject.type === "INTERNAL" && (_jsxs("div", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "is-allow-resubmission", checked: allowResubmission, onChange: (e) => {
                                        setAllowResubmission(e.target.checked);
                                    } }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "is-allow-resubmission" }, { children: translate("app.programApplications.student.review.status.change.modal.resubmission.checkbox.label") }))] })))] }))); // ) : (
                //   <span className="info-text">
                //     {translate(
                //       "app.programApplications.student.review.denied.modal.content.external"
                //     )}
                //   </span>
                // );
                primaryClick = (event) => {
                    event.preventDefault();
                    reviewStatusHandler("DENIED");
                    setOpenStatusChangeModal({ isOpen: false, status: "" });
                    setNoteForStudent("");
                };
                break;
            case "CONDITIONALLY_ACCEPTED":
                modalHeading = condStatusModalHeading;
                primaryText = condModalPrimaryText;
                modalContent = (_jsxs("div", Object.assign({ className: "modal-body status-change-modal-content" }, { children: [_jsxs("div", Object.assign({ className: "required-field" }, { children: [translate("app.cohort.requiredText.prefix"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), translate("app.cohort.requiredText.suffix")] })), _jsx("p", Object.assign({ className: "info-text" }, { children: translate("app.programApplications.student.review.cond.accepted.modal.content", {
                                petitioner: applicationObject.type === "INTERNAL"
                                    ? "student"
                                    : "applicant",
                            }) })), _jsx(TextAreaField, { fieldLabel: statusChangeModalInputLabel, fieldId: "status-change-note", isRequired: true, textareaError: noteError, fieldValue: noteForStudent, onChangeHandler: (event) => {
                                setNoteError(false);
                                setNoteForStudent(event.target.value);
                            }, placeHolder: condAcceptedNote, maxLength: 1000, errorMessage: noteErrorText }), _jsxs("div", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "is-allow-resubmission", checked: allowResubmission, onChange: (e) => {
                                        setAllowResubmission(e.target.checked);
                                    } }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "is-allow-resubmission" }, { children: translate("app.programApplications.student.review.status.change.modal.resubmission.checkbox.label") }))] }))] })));
                primaryClick = (event) => {
                    var _a;
                    event.preventDefault();
                    if (isNullOrEmpty(noteForStudent.trim())) {
                        setNoteError(true);
                        (_a = document.getElementById("status-change-note")) === null || _a === void 0 ? void 0 : _a.focus();
                        return;
                    }
                    else {
                        setNoteError(false);
                    }
                    reviewStatusHandler("CONDITIONALLY_ACCEPTED");
                    focusButtonComponent("status-change-btn");
                    setOpenStatusChangeModal({ isOpen: false, status: "" });
                    setNoteForStudent("");
                };
                break;
            case "ACCEPTED":
                modalHeading = acceptedStatusModalHeading;
                primaryText = acceptedStatusModalPrimaryText;
                modalContent = (
                //applicationObject.type === "INTERNAL" ? (
                _jsxs("div", Object.assign({ className: "modal-body status-change-modal-content" }, { children: [_jsx("p", Object.assign({ className: "info-text" }, { children: translate("app.programApplications.student.review.accepted.modal.content", {
                                petitioner: applicationObject.type === "INTERNAL"
                                    ? "student"
                                    : "applicant",
                            }) })), _jsx(TextAreaField, { fieldLabel: statusChangeModalInputLabel, fieldId: "status-change-note", isRequired: false, textareaError: false, fieldValue: noteForStudent, onChangeHandler: (event) => {
                                setNoteForStudent(event.target.value);
                            }, placeHolder: "", maxLength: 1000 })] })));
                // ) : (
                //   <span className="info-text">
                //     {translate(
                //       "app.programApplications.student.review.accepted.modal.content.external"
                //     )}
                //   </span>
                // );
                primaryClick = (event) => {
                    event.preventDefault();
                    reviewStatusHandler("ACCEPTED");
                    setOpenStatusChangeModal({ isOpen: false, status: "" });
                    setNoteForStudent("");
                };
                break;
            default:
        }
        return (_jsx(WmActionModal, { isOpen: isOpen, onPrimaryClick: primaryClick, onSecondaryClick: () => closeActionModalHandler(status), onClose: () => closeActionModalHandler(status), modalContent: modalContent, modalHeading: modalHeading, ariaLabelValue: primaryText, modalId: "status-change-modal-" + studentApplication.studentApplicationUuid, contentId: "content-status-change-modal-" +
                studentApplication.studentApplicationUuid, primaryText: primaryText, secondayText: secondaryText, modalType: applicationObject.type === "INTERNAL" ? "modal" : "dialog", elementToFocus: "status-change-note" }));
    };
    const closeActionModalHandler = (status) => {
        var _a;
        setOpenStatusChangeModal({ isOpen: false, status: "" });
        (_a = document
            .getElementById(status === "DENIED" ? "deny-submission-btn" : "accept-dropdown-btn")) === null || _a === void 0 ? void 0 : _a.focus();
        setNoteForStudent("");
        setNoteError(false);
    };
    const diffFromNow = (createdAt) => {
        const activityTime = Math.round(Math.abs(new Date().getTime() - new Date(createdAt).getTime()) / 60000 // 1 minute = 60,000 ms
        );
        return getMessageForActivityTime(activityTime, lessThanMintAgo, mintAgo, mintsAgo, hourAgo, hoursAgo);
    };
    const renderApplicationHistorySection = () => {
        var _a;
        return (_jsx("div", Object.assign({ className: `application-history ${studentApplicationSubmission.applicationStatus !== "AWAITING_REVIEW"
                ? "reviewed-status"
                : ""}` }, { children: _jsx(ToggleDisplay, Object.assign({ toggleBtnId: "app-history-toggle", showLabel: viewAppHistoryLabel, hideLabel: viewAppHistoryLabel }, { children: _jsx("ul", Object.assign({ className: `submission-history-list ${studentApplicationSubmission.applicationStatus !==
                        "AWAITING_REVIEW"
                        ? "reviewed-status"
                        : ""}` }, { children: (_a = studentApplicationSubmission === null || studentApplicationSubmission === void 0 ? void 0 : studentApplicationSubmission.applicationSubmissionHistoryList) === null || _a === void 0 ? void 0 : _a.map((submissionHistory) => {
                        return (_jsxs("li", Object.assign({ className: "submission-history-list-item" }, { children: [_jsx("p", Object.assign({ className: "history-timestamp" }, { children: !isNullOrEmpty(diffFromNow(submissionHistory === null || submissionHistory === void 0 ? void 0 : submissionHistory.createdAt))
                                        ? diffFromNow(submissionHistory === null || submissionHistory === void 0 ? void 0 : submissionHistory.createdAt)
                                        : translate("global.timestamp", {
                                            date: format(new Date(submissionHistory.createdAt), "PP"),
                                            time: format(new Date(submissionHistory.createdAt), "p"),
                                        }) })), _jsx("p", Object.assign({ className: "history-status-change-info" }, { children: isNullOrEmpty(submissionHistory === null || submissionHistory === void 0 ? void 0 : submissionHistory.oldStatus) &&
                                        (submissionHistory === null || submissionHistory === void 0 ? void 0 : submissionHistory.newStatus) === "AWAITING_REVIEW" &&
                                        (isNullOrEmpty(submissionHistory.createdBy) ||
                                            submissionHistory.createdBy ===
                                                (studentApplicationSubmission === null || studentApplicationSubmission === void 0 ? void 0 : studentApplicationSubmission.studentUuid))
                                        ? translate("app.programApplications.review.application.status.change.history.submit.info", {
                                            student: isNullOrEmpty(submissionHistory.createdBy)
                                                ? studentApplication.firstName
                                                : submissionHistory.createdByObject.firstName,
                                        })
                                        : !isNullOrEmpty(submissionHistory === null || submissionHistory === void 0 ? void 0 : submissionHistory.oldStatus) &&
                                            (submissionHistory === null || submissionHistory === void 0 ? void 0 : submissionHistory.newStatus) === "AWAITING_REVIEW" &&
                                            (isNullOrEmpty(submissionHistory.createdBy) ||
                                                submissionHistory.createdBy ===
                                                    (studentApplicationSubmission === null || studentApplicationSubmission === void 0 ? void 0 : studentApplicationSubmission.studentUuid))
                                            ? translate("app.programApplications.review.application.status.change.history.resubmit.info", {
                                                student: isNullOrEmpty(submissionHistory.createdBy)
                                                    ? studentApplication.firstName
                                                    : submissionHistory.createdByObject.firstName,
                                            })
                                            : translate("app.programApplications.student.review.submission.status.change.info", {
                                                previousStatus: getI18nValueByKey(submissionHistory.oldStatus, StudentApplicationStatus),
                                                updatedStatus: getI18nValueByKey(submissionHistory.newStatus, StudentApplicationStatus),
                                                admin: submissionHistory.createdByObject.lastName +
                                                    " " +
                                                    submissionHistory.createdByObject.firstName,
                                            }) })), !isNullOrEmpty(submissionHistory.note) && (_jsx(ToggleDisplay, Object.assign({ toggleBtnId: "note-to-student-toggle-" + submissionHistory.uuid, showLabel: showNotetoStudentLabel, hideLabel: hideNotetoStudentLabel }, { children: _jsx("p", Object.assign({ className: "history-note-to-student" }, { children: submissionHistory.note })) })))] }), submissionHistory.uuid));
                    }) })) })) })));
    };
    const renderSubmissionDetails = () => {
        var _a;
        return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "-header" }, { children: _jsx("h2", Object.assign({ className: "title" }, { children: translate("app.programApplications.student.review.submission.heading") })) })), studentApplicationSubmission ? (_jsxs("div", Object.assign({ className: "-body" }, { children: [_jsxs("div", Object.assign({ className: "submission-details" }, { children: [_jsx("h3", Object.assign({ className: "textfieldlabel" }, { children: translate("global.status") })), _jsxs("div", Object.assign({ className: "header section-header flex-row -justified -verticallycentered " }, { children: [_jsx("div", Object.assign({ className: getClassByStatus(studentApplicationSubmission.applicationStatus) }, { children: getI18nValueByKey(studentApplicationSubmission.applicationStatus, StudentApplicationStatus) })), (applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.connectedToArchivedLP) ? ("") : applicationObject.type === "EXTERNAL" &&
                                            studentApplicationSubmission.studentUuid ? null : (_jsx("div", { children: _jsx(WmButton, Object.assign({ buttonType: "textonly", icon: "f0fb", id: "status-change-btn", disabled: uploadInProgress || deleteInProgress, onClick: () => {
                                                    setOpenChangeStatus(true);
                                                }, placeholder: undefined }, { children: translate("app.openStudentReview.change.status") })) }))] }))] })), studentApplicationSubmission.applicationStatus ===
                            "AWAITING_REVIEW" ? (_jsxs("div", Object.assign({ className: "button-collection -btn-align" }, { children: [(applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.connectedToArchivedLP) ? ("") : (_jsxs("button", Object.assign({ "aria-label": "Deny", role: "button", className: `reject-btn ${uploadInProgress || deleteInProgress
                                        ? "disabled-mode"
                                        : ""}`, id: "deny-submission-btn", onClick: (e) => {
                                        e.preventDefault();
                                        setAllowResubmission(false);
                                        setOpenStatusChangeModal({
                                            isOpen: true,
                                            status: "DENIED",
                                        });
                                    }, disabled: uploadInProgress || deleteInProgress }, { children: [_jsx("span", { className: "deny-icon mdi mdi-cancel" }), translate("app.programApplications.student.review.submission.deny.button")] }))), _jsxs("div", Object.assign({ className: "accept-custom-dropdown actions wm-actions" }, { children: [(applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.connectedToArchivedLP) ? ("") : (_jsxs("button", Object.assign({ id: "accept-dropdown-btn", className: `button approve-btn ${uploadInProgress || deleteInProgress
                                                ? "disabled-mode"
                                                : ""}`, "aria-controls": "shared-list-btn", "aria-haspopup": "true", "aria-expanded": openMenu, onClick: () => {
                                                setOpenMenu(!openMenu);
                                            }, onKeyDown: () => {
                                                var _a;
                                                (_a = document.getElementById("accept-now-li")) === null || _a === void 0 ? void 0 : _a.focus();
                                            }, disabled: uploadInProgress || deleteInProgress }, { children: [translate("app.programApplications.student.review.submission.accept.button"), _jsx("span", { className: "accept-icon mdi mdi-chevron-down" })] }))), _jsxs("ul", Object.assign({ className: `dropdown left  actiondd nobullets ${openMenu ? "show" : ""}`, role: "menu", tabIndex: -1, "aria-label": "Accept", id: "accept-dropdown-list" }, { children: [_jsx("li", Object.assign({ className: "option dropdown-list-item", role: "menuitem", tabIndex: 0, id: "accept-now-li", onClick: (e) => {
                                                        e.preventDefault();
                                                        setAllowResubmission(false);
                                                        setOpenStatusChangeModal({
                                                            isOpen: true,
                                                            status: "ACCEPTED",
                                                        });
                                                    }, onKeyDown: handleKeyDown("ACCEPTED") }, { children: _jsxs("button", Object.assign({ className: "button link-button -textonly", id: "accept-now" }, { children: [_jsx("span", { className: "accept-now-icon mdi mdi-check-circle-outline" }), translate("app.programApplications.student.review.submission.accept.now.button")] })) })), _jsx("br", {}), _jsx("li", Object.assign({ className: "option dropdown-list-item", role: "menuitem", tabIndex: 0, id: "cond-accept-li", onClick: (e) => {
                                                        e.preventDefault();
                                                        setAllowResubmission(true);
                                                        setOpenStatusChangeModal({
                                                            isOpen: true,
                                                            status: "CONDITIONALLY_ACCEPTED",
                                                        });
                                                    }, onKeyDown: handleKeyDown("CONDITIONALLY_ACCEPTED") }, { children: _jsxs("button", Object.assign({ className: "button link-button -textonly", id: "conditionally-accept" }, { children: [_jsx("span", { className: "cond-accept-icon mdi mdi-progress-check" }), translate("app.programApplications.student.review.submission.conditionally.accept.button")] })) }))] }))] }))] }))) : null, ((_a = studentApplicationSubmission === null || studentApplicationSubmission === void 0 ? void 0 : studentApplicationSubmission.applicationSubmissionHistoryList) === null || _a === void 0 ? void 0 : _a.length) > 0
                            ? renderApplicationHistorySection()
                            : null] }))) : ("")] }));
    };
    const renderInternalRefMaterials = () => {
        return (_jsxs(Card, Object.assign({ classList: `internal-ref-materials-section ${studentApplicationSubmission.applicationStatus != "AWAITING_REVIEW"
                ? ""
                : "-height-prop"}`, headerTitle: internalRefMaterialsLabel }, { children: [_jsx("div", Object.assign({ className: "tab-cmpt-main" }, { children: _jsx(SubTabWmComponent, { selectedTab: selectedTab, onWmTabSelected: (event) => setSelectedTab(event.detail.tabId), tabOption: tabOption }) })), _jsx(WmTabPanel, Object.assign({ id: "tab-one", tabId: "item-1", placeholder: undefined }, { children: _jsx(AdminInternalNotes, { submissionObj: studentApplicationSubmission, applicationUuid: applicationObject.uuid, isConnectedToArchivedLP: applicationObject.connectedToArchivedLP }) })), _jsx(WmTabPanel, Object.assign({ id: "tab-two", tabId: "item-2", placeholder: undefined }, { children: _jsx("div", Object.assign({ className: `uploader-content  ${showNavigationBar ? "navigation-ht" : ""}` }, { children: !(applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.connectedToArchivedLP) ? (_jsxs(_Fragment, { children: [_jsxs("h3", Object.assign({ className: "textfieldlabel" }, { children: [translate("app.programApplications.student.review.submission.internal.reference.files.label"), _jsx(WmToggletip, { id: "reference-files-toggleTip", className: "toggletip", label: moreInfoLabel, tooltip: referenceFilesToggleTip, placeholder: undefined })] })), _jsx(WmUploader, Object.assign({ className: "uploader-container", icon: "f552", maxFiles: 5, maxSize: "100 MB", showInfo: "time", fileTypes: "docx doc pdf jpg png gif pptx ppt xlsx xls csv txt log xml", id: "upload-template-documents", buttonText: uploaderButtonText, onWmUploaderFilesSelected: (event) => onWmUploaderFilesSelected(event), ref: uploaderRef, placeholder: undefined }, { children: uploaderComponentFiles === null || uploaderComponentFiles === void 0 ? void 0 : uploaderComponentFiles.map((file) => {
                                        return (_jsx(WmFile, { id: file.id, name: file.name, type: file.type, lastUpdated: !isNullOrEmpty(file === null || file === void 0 ? void 0 : file.lastUpdated)
                                                ? format(generateUTCDate(new Date(file.lastUpdated)), "MM/dd/yyyy h:mm a")
                                                : "", onWmFileDownload: (event) => onWmUploaderDownloadFile(event), onWmFileDelete: (event) => onWmUploaderDeleteFile(event), progress: file.progress, placeholder: undefined }, file.id));
                                    }) }), JSON.stringify(uploaderComponentFiles))] })) : uploaderComponentFiles.length > 0 ? (_jsx(WmFileList, Object.assign({ "show-info": "size", placeholder: undefined }, { children: uploaderComponentFiles === null || uploaderComponentFiles === void 0 ? void 0 : uploaderComponentFiles.map((file) => {
                                return (_jsx(WmFile, { id: file.id, name: file.name, type: file.type, lastUpdated: !isNullOrEmpty(file === null || file === void 0 ? void 0 : file.lastUpdated)
                                        ? format(generateUTCDate(new Date(file.lastUpdated)), "MM/dd/yyyy h:mm a")
                                        : "", fileActions: file.fileActions, onWmFileDownload: (event) => {
                                        onWmUploaderDownloadFile(event);
                                    }, placeholder: undefined }, file.id));
                            }) }))) : (_jsx("div", Object.assign({ className: "empty-response-text" }, { children: translate("app.programApplications.student.review.submission.no.files.uploaded") }))) })) }))] })));
    };
    const mainContent = () => {
        return (_jsxs(_Fragment, { children: [(applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.connectedToArchivedLP) ? (_jsx("div", Object.assign({ className: "archive-application-content" }, { children: _jsx("div", Object.assign({ className: "archive-warning-text" }, { children: translate("app.application.timeline.student.archive.text") })) }))) : (""), _jsxs("section", Object.assign({ className: `review-container ${studentApplicationSubmission.applicationStatus !== "AWAITING_REVIEW"
                        ? ""
                        : "-height-prop"} ${(applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.connectedToArchivedLP)
                        ? "archived-review-container"
                        : ""}` }, { children: [_jsxs("div", Object.assign({ className: `review-submissions-content ${showNavigationBar ? "navigation-ht" : ""}` }, { children: [_jsxs("div", Object.assign({ className: "review-side-layout" }, { children: [_jsx("div", Object.assign({ className: "review-card" }, { children: _jsx("div", Object.assign({ tabIndex: 0, className: "submission-review-section" }, { children: renderSubmissionDetails() })) })), _jsx("div", Object.assign({ className: "internal-ref-section" }, { children: renderInternalRefMaterials() }))] })), _jsx("div", Object.assign({ className: `application-review-section ${showNavigationBar ? "navigation-ht" : ""}` }, { children: _jsx(ViewApplicationResponses, { applicationObject: applicationObject, studentApplicationSubmission: studentApplicationSubmission }) }))] })), showNavigationBar ? navigationFooter() : "", renderStatusChangeModal(openStatusChangeModal.status, openStatusChangeModal.isOpen)] }))] }));
    };
    useEffect(() => {
        setAttachedFiles(studentApplicationSubmission.files);
        setHookMetadata(studentApplicationSubmission.studentApplicationUuid);
    }, [studentApplicationSubmission.files]);
    useEffect(() => {
        const uploaderFiles = attachedFiles === null || attachedFiles === void 0 ? void 0 : attachedFiles.map((file) => {
            let progressIndicator = !(applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.connectedToArchivedLP) &&
                ((uploadInProgress || deleteInProgress) &&
                    currentFiles.indexOf(file.uuid) > -1
                    ? { progress: 0 }
                    : { progress: 100 });
            let uploaderFile = Object.assign({ id: file.uuid, name: file.file_name.substr(0, file.file_name.lastIndexOf(".")), type: file.file_name.substr(file.file_name.lastIndexOf(".") + 1), lastUpdated: file.inserted_at, fileActions: uploadInProgress ||
                    deleteInProgress ||
                    (applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.connectedToArchivedLP)
                    ? "download"
                    : "download delete" }, progressIndicator);
            return uploaderFile;
        });
        setUploaderComponentFiles(uploaderFiles);
        dispatch(removeFileFromApplicationSubmissionList({
            uuid: studentApplication === null || studentApplication === void 0 ? void 0 : studentApplication.studentApplicationUuid,
            files: attachedFiles,
        }));
    }, [attachedFiles, uploadInProgress, deleteInProgress]);
    useEffect(() => {
        let flyoutFocusTimer;
        if (!isPageLoading) {
            flyoutFocusTimer = setTimeout(() => {
                openFlyout("review-submission-flyout", "start-reviewing-btn", "status-change-btn");
            }, 100);
        }
        return () => clearTimeout(flyoutFocusTimer);
    }, [isPageLoading]);
    useEffect(() => {
        const fn = (event) => {
            if (!event.target.matches(".approve-btn")) {
                if (openMenu) {
                    setOpenMenu(false);
                }
            }
        };
        window.addEventListener("click", fn);
        return () => window.removeEventListener("click", fn);
    }, [openMenu]);
    useEffect(() => {
        if (currentSubmissionIndex + 1 === awaitingReviewSubmissionList.length &&
            awaitingReviewSubmissionList.length != 1) {
            focusButtonComponent("previous-student-btn");
        }
        else if (currentSubmissionIndex === 0 &&
            updatedByEvent &&
            awaitingReviewSubmissionList.length != 1) {
            focusButtonComponent("next-student-btn");
        }
    }, [currentSubmissionIndex]);
    return (_jsx("div", { children: isPageLoading ? (_jsx("div", Object.assign({ className: "loader -fullscreen" }, { children: _jsx("div", Object.assign({ className: "content content-loader", role: "alert" }, { children: "Loading..." })) }))) : (_jsxs(_Fragment, { children: [_jsx(Flyout, { id: "review-submission-flyout", mainContent: mainContent(), closeFlyoutHandler: closeStudentReviewHandler, heading: studentApplicationSubmission.lastName +
                        ", " +
                        studentApplicationSubmission.firstName, subHeading: "", primaryButton: translate("app.editTimeline.primaryButtonText"), primaryActionHandler: closeStudentReviewHandler, breadCrum: (_g = applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.title) === null || _g === void 0 ? void 0 : _g.toUpperCase(), showSecondaryButton: false, secondaryButton: translate("app.cohort.secondaryButtonText"), secondaryButtonActionHandler: () => { }, disableActionButtons: uploadInProgress || deleteInProgress, showStatus: "" }), openChangeStatus && (_jsx(ChangeSubmissionStatus, { setOpenChangeStatus: setOpenChangeStatus, studentUuid: getStudentIdentifierForBackend(studentApplicationSubmission), applicationObject: applicationObject, oldStatus: studentApplicationSubmission.applicationStatus, studentApplicationId: (_h = (showNavigationBar
                        ? awaitingReviewSubmissionList[currentSubmissionIndex]
                        : studentApplication)) === null || _h === void 0 ? void 0 : _h.studentApplicationUuid }))] })) }));
}
