import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getCategoryByKey, getIdByKey, } from "../../features/common/commonActions";
import { TemplateComponentList } from "./data/TemplateComponentList";
import translate from "../../i18n/translate";
import ResourceLink from "./template-components/ResourceLink";
import ResourceAttachment from "./template-components/ResourceAttachment";
import { getUploaderFiles } from "../common/commonActions";
import { isNullOrEmpty } from "../../static/js/commons";
import { format } from "date-fns";
import { useIntl } from "react-intl";
const ViewApplicationResponses = ({ applicationObject, studentApplicationSubmission, }) => {
    var _a;
    let defaultComponent = 0;
    let isDefault = false;
    const firstNameLabel = useIntl().formatMessage({
        id: "app.programApplications.template.standard.template.field.name",
    }, {
        value: "First",
    });
    const lastNameLabel = useIntl().formatMessage({
        id: "app.programApplications.template.standard.template.field.name",
    }, {
        value: "Last",
    });
    const emailLabel = useIntl().formatMessage({
        id: "app.programApplications.template.standard.template.field.institution.email",
    });
    const isNextSiblingCategorySimilar = (currentComp, nextComp) => {
        return (getCategoryByKey(currentComp.componentType, TemplateComponentList) ===
            getCategoryByKey(nextComp === null || nextComp === void 0 ? void 0 : nextComp.componentType, TemplateComponentList) &&
            currentComp.uuid !== nextComp.uuid);
    };
    const findValue = (templateUuid, templateComponentUuid, componentName) => {
        var _a, _b;
        if (studentApplicationSubmission.responses)
            for (let x of studentApplicationSubmission.responses) {
                if (x.templateUuid === templateUuid &&
                    x.templateComponentUuid === templateComponentUuid) {
                    return componentName === "LINK_INPUT"
                        ? [x.responseText, (_a = x.linkTitle) === null || _a === void 0 ? void 0 : _a.trim()]
                        : componentName === "ACKNOWLEDGEMENT_CHECKBOX"
                            ? x.isAcknowledged
                            : componentName === "MULTISELECT_CHECKBOXES" ||
                                componentName === "RADIO_BUTTON"
                                ? (_b = [...x.multiselectOptions]) === null || _b === void 0 ? void 0 : _b.sort((a, b) => (a === null || a === void 0 ? void 0 : a.sortOrder) - (b === null || b === void 0 ? void 0 : b.sortOrder))
                                : componentName === "FILE_UPLOAD"
                                    ? x.files
                                    : x.responseText;
                }
            }
        return "";
    };
    const renderResourceComponent = (applicationComponent, similarCategory) => {
        return (_jsx("div", Object.assign({ className: `-compo-resource-content ${getIdByKey(applicationComponent.componentType, TemplateComponentList)} ${similarCategory ? "similar-category" : ""}` }, { children: applicationComponent.componentType === "LINK_RESOURCE" ? (_jsx(ResourceLink, { id: applicationComponent.uuid, url: applicationComponent.inputText, displayText: applicationComponent.componentLabel, appTemplateView: true })) : applicationComponent.componentType === "ATTACHMENT" ? (_jsx(ResourceAttachment, { id: applicationComponent.uuid, displayLabel: applicationComponent.componentLabel, attachedFilesList: getUploaderFiles(applicationComponent), files: applicationComponent.files, appTemplateView: true })) : (applicationComponent.inputText) })));
    };
    const renderStudentInputComponent = (applicationComponent, similarCategory, uuid, firstComp) => {
        var _a, _b, _c, _d;
        let linkInputResponse = findValue(uuid, applicationComponent.uuid, "LINK_INPUT");
        const getFileUploads = (files) => {
            if (files) {
                let uploaderFiles = files.map((file) => {
                    let uploaderFile = {
                        id: file.uuid,
                        name: file.file_name.substr(0, file.file_name.lastIndexOf(".")),
                        type: file.file_name.substr(file.file_name.lastIndexOf(".") + 1),
                        lastUpdated: file.inserted_at,
                        fileActions: "",
                    };
                    return uploaderFile;
                });
                return uploaderFiles;
            }
        };
        return (_jsxs("div", Object.assign({ className: `-compo-student-response ${similarCategory ? "similar-category" : ""} ${applicationComponent.componentType === "DEFAULT_COMPONENT"
                ? firstComp
                    ? "first-comp"
                    : similarCategory
                        ? "def-comp-response"
                        : "not-similar"
                : ""}` }, { children: [_jsx("p", Object.assign({ className: "-compo-label" }, { children: applicationComponent.componentType === "ACKNOWLEDGEMENT_CHECKBOX" ||
                        applicationComponent.componentType === "MULTISELECT_CHECKBOXES" ||
                        applicationComponent.componentType === "RADIO_BUTTON" ||
                        applicationComponent.componentType === "FILE_UPLOAD" ||
                        applicationComponent.componentType === "DROPDOWN"
                        ? applicationComponent.componentLabel
                        : applicationComponent.componentType === "DEFAULT_COMPONENT"
                            ? null
                            : applicationComponent.inputText })), _jsx("div", Object.assign({ className: `-compo-answer ${getIdByKey(applicationComponent.componentType, TemplateComponentList)}` }, { children: applicationComponent.componentType === "LINK_INPUT" ? (!isNullOrEmpty(findValue(uuid, applicationComponent.uuid, "LINK_INPUT")[0]) ? (_jsx(ResourceLink, { id: applicationComponent.uuid, url: linkInputResponse[0], displayText: linkInputResponse[1], appTemplateView: true })) : (_jsx("span", Object.assign({ className: "empty-response-text" }, { children: translate("app.programApplications.student.review.submission.no.link.inserted") })))) : applicationComponent.componentType === "MULTISELECT_CHECKBOXES" ||
                        applicationComponent.componentType === "RADIO_BUTTON" ? (_jsx(_Fragment, { children: !isNullOrEmpty(findValue(uuid, applicationComponent.uuid, applicationComponent.componentType)) ? ((_a = findValue(uuid, applicationComponent.uuid, applicationComponent.componentType)) === null || _a === void 0 ? void 0 : _a.map((option) => {
                            return (_jsx("p", Object.assign({ className: "response-option" }, { children: option.text }), option.uuid));
                        })) : (_jsx("span", Object.assign({ className: "empty-response-text" }, { children: translate("app.programApplications.student.review.submission.no.item.selected") }))) })) : applicationComponent.componentType === "FILE_UPLOAD" ? (!isNullOrEmpty(findValue(uuid, applicationComponent.uuid, "FILE_UPLOAD")) ? (_jsx("div", Object.assign({ className: "no-pad" }, { children: _jsx(ResourceAttachment, { id: applicationComponent.uuid, displayLabel: "", attachedFilesList: getFileUploads(findValue(uuid, applicationComponent.uuid, "FILE_UPLOAD")), files: findValue(uuid, applicationComponent.uuid, "FILE_UPLOAD"), appTemplateView: true }) }))) : (_jsx("span", Object.assign({ className: "empty-response-text" }, { children: translate("app.programApplications.student.review.submission.no.files.uploaded") })))) : applicationComponent.componentType ===
                        "ACKNOWLEDGEMENT_CHECKBOX" ? (!isNullOrEmpty(findValue(uuid, applicationComponent.uuid, "ACKNOWLEDGEMENT_CHECKBOX")) &&
                        findValue(uuid, applicationComponent.uuid, "ACKNOWLEDGEMENT_CHECKBOX") === true ? (_jsxs("div", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: `checkbox-option-${applicationComponent.uuid}`, "aria-disabled": "true", checked: true, onChange: (event) => {
                                    event.preventDefault();
                                } }), _jsx("label", Object.assign({ className: "chkbx label-display", htmlFor: `checkbox-option-${applicationComponent.uuid}` }, { children: _jsx("div", { children: applicationComponent.inputText }) }))] }))) : (_jsx("span", Object.assign({ className: "empty-response-text" }, { children: translate("app.programApplications.student.review.submission.acknowledgement.not.selected") })))) : !isNullOrEmpty(findValue(uuid, applicationComponent.uuid, "")) ? (applicationComponent.componentType === "DATE_SELECTOR" ? (format(new Date(findValue(uuid, applicationComponent.uuid, "") + "T00:00:00"), "MM/dd/yyyy")) : (findValue(uuid, applicationComponent.uuid, ""))) : applicationComponent.componentType === "DEFAULT_COMPONENT" ? (_jsxs(_Fragment, { children: [_jsx("p", { children: firstNameLabel }), _jsx("div", Object.assign({ className: "-compo-answer -compo-answer-text" }, { children: applicationObject.type === "EXTERNAL"
                                    ? (_b = studentApplicationSubmission.externalUser) === null || _b === void 0 ? void 0 : _b.firstName
                                    : studentApplicationSubmission === null || studentApplicationSubmission === void 0 ? void 0 : studentApplicationSubmission.firstName })), _jsx("p", { children: lastNameLabel }), _jsx("div", Object.assign({ className: "-compo-answer -compo-answer-text" }, { children: applicationObject.type === "EXTERNAL"
                                    ? (_c = studentApplicationSubmission.externalUser) === null || _c === void 0 ? void 0 : _c.lastName
                                    : studentApplicationSubmission === null || studentApplicationSubmission === void 0 ? void 0 : studentApplicationSubmission.lastName })), _jsx("p", { children: emailLabel }), _jsx("div", Object.assign({ className: "-compo-answer -compo-answer-text" }, { children: applicationObject.type === "EXTERNAL"
                                    ? (_d = studentApplicationSubmission.externalUser) === null || _d === void 0 ? void 0 : _d.email
                                    : studentApplicationSubmission === null || studentApplicationSubmission === void 0 ? void 0 : studentApplicationSubmission.email }))] })) : (_jsx("span", Object.assign({ className: "empty-response-text" }, { children: applicationComponent.componentType === "DROPDOWN"
                            ? translate("app.programApplications.student.review.submission.no.item.selected")
                            : translate("app.programApplications.student.review.submission.no.response.submitted") }))) }))] })));
    };
    return (_jsxs("div", Object.assign({ className: "-application-content", tabIndex: 0, "aria-label": "Student Response" }, { children: [_jsx("h4", Object.assign({ className: "-app-title" }, { children: applicationObject.title })), _jsx("div", Object.assign({ className: "-app-comp-list" }, { children: (_a = applicationObject.applicationAppTemplates) === null || _a === void 0 ? void 0 : _a.map((appTemplate, index) => {
                    var _a;
                    if (isDefault) {
                        defaultComponent++;
                    }
                    return (_a = appTemplate.applicationTemplate.templateComponentList) === null || _a === void 0 ? void 0 : _a.filter((comp) => {
                        if (comp.componentType === "DEFAULT_COMPONENT") {
                            isDefault = true;
                        }
                        return (defaultComponent === 0 ||
                            (defaultComponent > 0 &&
                                comp.componentType !== "DEFAULT_COMPONENT"));
                    }).map((applicationComponent, i) => {
                        if (applicationComponent.componentType === "DEFAULT_COMPONENT") {
                            isDefault = true;
                        }
                        return (_jsx("div", Object.assign({ id: applicationComponent.componentType.toLowerCase() +
                                "_" +
                                applicationComponent.uuid, className: "-compo-list-item" }, { children: getCategoryByKey(applicationComponent.componentType, TemplateComponentList) === "RESOURCE_CONTENT"
                                ? renderResourceComponent(applicationComponent, isNextSiblingCategorySimilar(applicationComponent, appTemplate.applicationTemplate
                                    .templateComponentList[i + 1]))
                                : renderStudentInputComponent(applicationComponent, isNextSiblingCategorySimilar(applicationComponent, appTemplate.applicationTemplate
                                    .templateComponentList[i + 1]), appTemplate.applicationTemplate.uuid, i === 0 && index === 0) }), applicationComponent.uuid));
                    });
                }) }))] })));
};
export default ViewApplicationResponses;
