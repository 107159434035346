var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmNavigator, WmOption, WmSelect, } from "@watermarkinsights/ripple-react";
import { switchInstituteApplications, switchInstituteGateways, userSelector, getLoggedInUserInfo, getLoggedInUserInfoProgramApp, } from "../../login/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import "../sidenav/SideNav.scss";
import { WmNavigationHamburger } from "@watermarkinsights/ripple-react";
import { logoutGateways, logoutSll, logoutProgramApplications, } from "../../login/UserSlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Header({ activeItem }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const { userInfo, userInfoProgApp, redirectTo } = useSelector(userSelector);
    const navigate = useNavigate();
    const userObj = activeItem === "gateways" ? userInfo : userInfoProgApp;
    const [currentInstitute, setCurrentInstitute] = useState(((_b = (_a = userObj === null || userObj === void 0 ? void 0 : userObj.sllUser) === null || _a === void 0 ? void 0 : _a.institutions) === null || _b === void 0 ? void 0 : _b.find((inst) => { var _a; return (inst === null || inst === void 0 ? void 0 : inst.platform_uuid) === ((_a = userObj.sllUser) === null || _a === void 0 ? void 0 : _a.institution_uuid); })) || null);
    const institutionList = ((_d = (_c = userObj === null || userObj === void 0 ? void 0 : userObj.sllUser) === null || _c === void 0 ? void 0 : _c.institutions) === null || _d === void 0 ? void 0 : _d.filter((inst) => activeItem === "gateways"
        ? inst.gateways === true
        : inst.applications === true)) || [];
    const user = activeItem === "gateways"
        ? {
            sllUser: userInfo === null || userInfo === void 0 ? void 0 : userInfo.sllUser,
            user: userInfo === null || userInfo === void 0 ? void 0 : userInfo.gatewaysUser,
        }
        : {
            sllUser: userInfoProgApp === null || userInfoProgApp === void 0 ? void 0 : userInfoProgApp.sllUser,
            user: userInfoProgApp === null || userInfoProgApp === void 0 ? void 0 : userInfoProgApp.applicationsUser,
        };
    const dispatch = useDispatch();
    const selectInstitutionHandler = (institution) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        setCurrentInstitute(institution);
        const institutionPlatformUuid = institution.platform_uuid;
        let bothAppsActive = ((_b = (_a = userObj === null || userObj === void 0 ? void 0 : userObj.sllUser) === null || _a === void 0 ? void 0 : _a.available_apps) === null || _b === void 0 ? void 0 : _b.includes("applications")) &&
            ((_d = (_c = userObj === null || userObj === void 0 ? void 0 : userObj.sllUser) === null || _c === void 0 ? void 0 : _c.available_apps) === null || _d === void 0 ? void 0 : _d.includes("gateways")) &&
            (institution === null || institution === void 0 ? void 0 : institution.applications) &&
            (institution === null || institution === void 0 ? void 0 : institution.gateways);
        bothAppsActive &&
            Promise.all([
                dispatch(switchInstituteGateways({ institutionPlatformUuid })),
                dispatch(switchInstituteApplications({ institutionPlatformUuid })),
            ]).then(() => {
                Promise.all([
                    dispatch(getLoggedInUserInfo()),
                    dispatch(getLoggedInUserInfoProgramApp()),
                ]).then(() => {
                    navigate(`${localStorage.getItem("goToHome")}`);
                });
            });
        if (!bothAppsActive &&
            (institution === null || institution === void 0 ? void 0 : institution.applications) &&
            ((_f = (_e = userObj === null || userObj === void 0 ? void 0 : userObj.sllUser) === null || _e === void 0 ? void 0 : _e.available_apps) === null || _f === void 0 ? void 0 : _f.includes("applications"))) {
            dispatch(switchInstituteApplications({ institutionPlatformUuid })).then(() => {
                dispatch(getLoggedInUserInfoProgramApp()).then(() => {
                    navigate(`${localStorage.getItem("goToHome")}`);
                });
            });
        }
        else if (!bothAppsActive &&
            (institution === null || institution === void 0 ? void 0 : institution.gateways) &&
            ((_h = (_g = userObj === null || userObj === void 0 ? void 0 : userObj.sllUser) === null || _g === void 0 ? void 0 : _g.available_apps) === null || _h === void 0 ? void 0 : _h.includes("gateways"))) {
            dispatch(switchInstituteGateways({ institutionPlatformUuid })).then(() => {
                dispatch(getLoggedInUserInfo()).then(() => {
                    navigate(`${localStorage.getItem("goToHome")}`);
                });
            });
        }
    };
    const logout = () => __awaiter(this, void 0, void 0, function* () {
        if (localStorage.getItem("x-xsrf-token") &&
            localStorage.getItem("application-xsrf-token")) {
            dispatch(logoutGateways()).then(() => dispatch(logoutProgramApplications()).then(({ payload }) => {
                var _a, _b;
                localStorage.clear();
                dispatch(logoutSll({ url: (_b = (_a = user === null || user === void 0 ? void 0 : user.sllUser) === null || _a === void 0 ? void 0 : _a.navigation) === null || _b === void 0 ? void 0 : _b.logout })).then(() => {
                    let url = payload;
                    window.open(url, "_self");
                });
            }));
        }
        else if (localStorage.getItem("x-xsrf-token")) {
            dispatch(logoutGateways()).then(({ payload }) => {
                var _a, _b;
                localStorage.clear();
                dispatch(logoutSll({ url: (_b = (_a = user === null || user === void 0 ? void 0 : user.sllUser) === null || _a === void 0 ? void 0 : _a.navigation) === null || _b === void 0 ? void 0 : _b.logout })).then(() => {
                    let url = payload;
                    window.open(url, "_self");
                });
            });
        }
        else if (localStorage.getItem("application-xsrf-token")) {
            dispatch(logoutProgramApplications()).then(({ payload }) => {
                var _a, _b;
                localStorage.clear();
                dispatch(logoutSll({ url: (_b = (_a = user === null || user === void 0 ? void 0 : user.sllUser) === null || _a === void 0 ? void 0 : _a.navigation) === null || _b === void 0 ? void 0 : _b.logout })).then(() => {
                    let url = payload;
                    window.open(url, "_self");
                });
            });
        }
    });
    return (_jsxs("header", Object.assign({ className: "top-bar", "data-testid": "header-watermark" }, { children: [_jsx("div", Object.assign({ className: "hamburger-header" }, { children: _jsx(WmNavigationHamburger, { "nav-id": "main-nav", placeholder: undefined }) })), _jsx("h1", Object.assign({ className: "logo sll-logo" }, { children: _jsx("a", Object.assign({ href: ((_e = user === null || user === void 0 ? void 0 : user.user) === null || _e === void 0 ? void 0 : _e.sllRole) !== "admin"
                        ? ((_f = user === null || user === void 0 ? void 0 : user.user) === null || _f === void 0 ? void 0 : _f.associatedToCourseSection)
                            ? (_j = (_h = (_g = user === null || user === void 0 ? void 0 : user.sllUser) === null || _g === void 0 ? void 0 : _g.navigation) === null || _h === void 0 ? void 0 : _h.in_progress) === null || _j === void 0 ? void 0 : _j.in_progress
                            : (_m = (_l = (_k = user === null || user === void 0 ? void 0 : user.sllUser) === null || _k === void 0 ? void 0 : _k.navigation) === null || _l === void 0 ? void 0 : _l.in_progress) === null || _m === void 0 ? void 0 : _m.timeline
                        : (_p = (_o = user === null || user === void 0 ? void 0 : user.sllUser) === null || _o === void 0 ? void 0 : _o.navigation) === null || _p === void 0 ? void 0 : _p.academics }, { children: _jsx("img", { src: "/images/student_learning_licensure_logo.svg", alt: "Watermark" }) })) })), _jsx("div", Object.assign({ className: "row select-row" }, { children: institutionList.length > 1 ? (_jsx(WmSelect, Object.assign({ requiredField: false, id: "filter-cohort-licensure-plan" }, { children: institutionList.map((institution) => (_jsx(WmOption, Object.assign({ "aria-label": institution.name, onWmOptionSelected: () => {
                            selectInstitutionHandler(institution);
                        }, selected: institution.platform_uuid === (currentInstitute === null || currentInstitute === void 0 ? void 0 : currentInstitute.platform_uuid) }, { children: institution.name }), "option" + institution.platform_uuid))) }), "select-plan")) : (_jsx("div", Object.assign({ className: "user-institution" }, { children: currentInstitute === null || currentInstitute === void 0 ? void 0 : currentInstitute.name }))) })), _jsx("div", Object.assign({ className: "menus", role: "application" }, { children: _jsx(WmNavigator, { id: "navigator-example", "user-name": `${(_q = user === null || user === void 0 ? void 0 : user.user) === null || _q === void 0 ? void 0 : _q.firstName} ${(_r = user === null || user === void 0 ? void 0 : user.user) === null || _r === void 0 ? void 0 : _r.lastName}`, email: (_s = user === null || user === void 0 ? void 0 : user.user) === null || _s === void 0 ? void 0 : _s.email, "auth-type": "2", products: "[]", onWmNavigatorLogout: logout, placeholder: undefined }) }))] })));
}
